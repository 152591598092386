<script>

import { mapGetters, mapActions, mapState } from 'vuex';

import http from '@/oauth-client';
import explorerHttp from '@/explorer-client';


export default {
  components: {
   

  
  },
  mounted() {
    this.init();
  },
  props: {
    impersonation: {
      type: Boolean
    }
  },
  data() {
    return {
      predefinedRiskProfiles: []
    };
  },
  computed: {
    ...mapState('signalr', {
      connectionId:'connectionId'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      permissions: 'permissions'
    }),
     ...mapGetters('riskprofiles', {
    
      riskProfiles: 'activeItems'
    }),
    ...mapGetters('portfolios', {
      loading: 'loading',
      items: 'items',
    //  deleting: 'deleting'
    }),
    total() {
      return this.items.find(x => x.id === '$$TOTAL$$');
    }
  },
  watch: {
    connectionId(newValue, oldValue) {
        if(newValue && (newValue !== oldValue)) {
          this.init();
        }
      },
  },
  methods: {
    ...mapActions('portfolios', {
      loadUserPortfolios: 'loadUserPortfolios',
    }),
    ...mapActions('execution', {
      
    }),
    async init() {
      if(this.connectionId) {
        this.loadPredefinedRiskProfiles();
      this.loadUserPortfolios();
      } else {
        console.log('clientId is null. waiting...');
      }
    },
    getPortfolioRiskProfile(riskProfileId) {
      return [...this.riskProfiles,...this.predefinedRiskProfiles].find(x => x.id === riskProfileId).name;
    },
    calcSumDailyReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcDailyReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    calcSumMonthlyReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcMonthlyReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    calcSumTotalReturn () {
      let sum = 0;
      this.items.forEach(portfolio => {
        sum += this.calcTotalReturn(portfolio);
      })
      return parseFloat(sum.toFixed(2));
    },
    // calcDailyReturn (portfolio) { 
    //   return parseFloat((portfolio.data && portfolio.data.performenceInformation.dailyReturn || 0).toFixed(2));
    // },
    // calcMonthlyReturn (portfolio) {
    //   return  parseFloat((portfolio.data && portfolio.data.basket ? this.calcReturn(portfolio.data.basket, 'monthlyReturn') : 0).toFixed(2));
    // },
    // calcTotalReturn (portfolio) {
    //   return parseFloat((portfolio.data &&  portfolio.data.basket ? this.calcReturn(portfolio.data.basket, 'totalReturn') : 0).toFixed(2));
    // },
    calcReturn (basket, propName) {
      let sum = 0;
       const keys = Object.keys(basket.items);
     
        keys.forEach(key => {
        const bi = basket.items[key];
        sum += bi[propName] || 0;
      })
      return parseFloat(sum.toFixed(2));
    },
   
    async loadPredefinedRiskProfiles() {
      const userResp = await explorerHttp.get(`data/User/objects/current`);
      const user = userResp.data;
      if( user.Company?.Id) {
        const resp = await http.get('api/predefined-risk-profiles?companyId=' + user.Company?.Id);
        this.predefinedRiskProfiles = resp.data;
      }
     
    },
  }
};
</script>

<template>

  <div class="d-flex flex-column justify-content-center">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="card p-0 cardc8">
            <div class="card-content m-3">
          
              <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                <h5 class="mr-auto">Select Portfolio for review</h5>

              </div>
              <div v-if="loading && !executing" class="p-4">
                <i class="fa fa-spinner fa-spin mr-2"></i>Loading...
              </div> 
              <div v-if="!loading || executing">
                <div class="text-muted mt-4" v-if="items.length === 0">
                  No portfolios found...
                </div>

                <div  class="table-responsive" v-if="items.length > 0">
                  <table class="table table-borderless ">
                    <thead>
                      <tr>
                      
                        <th></th>
                        <th >Risk Profile</th>
                        <th>Status</th>
                        <th  style="width:120px;max-width:120px;">
                          Position 
                        </th>
                        <th  class="col">
                          Proposed Position 
                        </th>
                        <th  class="col">
                          Daily Return
                        </th>
                        <th  class="col">
                          Monthly Return
                        </th>
                        <th  class="col">
                          Total Return
                        </th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="portfolio in items" >
                        <tr :key="portfolio.id"   v-if="portfolio.id !== '$$TOTAL$$'">
                          
                          <td  class="text-nowrap">
                            <a 
                              class="mr-2"
                              :href="'/portfolios/' + portfolio.id + '/edit'"
                            >
                              {{portfolio.displayName}}
                            </a>
                            
                          </td>
                          <td class="text-nowrap">
                            {{getPortfolioRiskProfile(portfolio.constraints.riskProfileId)}}
                          </td>
                          <th><span class="badge badge-info">{{portfolio.data.status}}</span>  </th>
                          <td>
                          
                          </td>
                          <td>
                          
                          </td>
                          <td>
                            <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.dailyReturn.toFixed(2)}}</span>
                      
                          </td>
                          <td>
                            <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.monthlyReturn.toFixed(2)}}</span>
                          </td>
                          <td>
                            <span v-if="portfolio.data && portfolio.data.performenceInformation">{{portfolio.data.performenceInformation.totalReturn.toFixed(2)}} </span>
                          </td>
                        
                        </tr>
                      
                      </template>
                      <tr class="bg-light" v-if="total" :key="total.id">
                       
                        <td>
                          <b><a href="javascript:void(0)" @click="selectPortfolio(total)">Total</a></b>
                        </td>
                        <td >
                          
                        </td>
                        <td>
                          
                        </td>
                        <td>
                          
                        </td>
                        <td>
                          
                        </td>
                        <td>
                          <b>{{total.data.performenceInformation.dailyReturn.toFixed(2)}}</b> 
                        </td>
                        <td>
                          <b>{{total.data.performenceInformation.monthlyReturn.toFixed(2)}}</b> 
                        </td>
                        <td>
                          <b>{{total.data.performenceInformation.totalReturn.toFixed(2)}} </b>
                        </td>
                        <td class="text-center col text-nowrap" v-if="!impersonation">
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  
</template>